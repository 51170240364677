import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MediaTitle } from 'src/app/shared/models/MediaTitle';

@Component({
  selector: 'app-title-card',
  templateUrl: './title-card.component.html',
  styleUrls: ['./title-card.component.scss']
})
export class TitleCardComponent {

  @Input()
  title: MediaTitle;

  @Output()
  titleToDelete: EventEmitter<MediaTitle> = new EventEmitter();

  constructor() { }

  deleteTitle() {
    this.titleToDelete.emit(this.title);
  }

}
