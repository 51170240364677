import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatToolbarModule} from '@angular/material/toolbar';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NavbarComponent} from './core/navbar/navbar.component';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {HttpClientModule} from '@angular/common/http';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { SearchComponent } from './modules/titles/pages/search/search.component';
import { SearchBarComponent } from './modules/titles/components/search-bar/search-bar.component';
import { TitleDisplayComponent } from './modules/titles/components/title-display/title-display.component';
import { OptionModelDirective } from './modules/titles/directives/option-model.directive';
import { TitleCardComponent } from './modules/titles/components/title-card/title-card.component';
import {MatCardModule} from '@angular/material/card';
import { SearchResultComponent } from './modules/titles/components/search-result/search-result.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import { ConfigurationService } from './core/services/api/configuration.service';

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    SearchBarComponent,
    NavbarComponent,
    TitleDisplayComponent,
    OptionModelDirective,
    TitleCardComponent,
    SearchResultComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatCardModule,
    MatGridListModule,
    MatIconModule
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: (configService: ConfigurationService) => () => configService.loadConfigurations().toPromise(),
    deps: [ConfigurationService],
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
