import {Component, OnInit, OnDestroy} from '@angular/core';
import { TitleService } from 'src/app/core/services/title/title.service';
import { MediaTitle } from 'src/app/shared/models/MediaTitle';
import { Subscription } from 'rxjs';

const DATA: MediaTitle[] = [
  {id:1, Title:"The Flash", Year: "2014-", imdbID: "tt3107288", Type:"series", Poster:"https://m.media-amazon.com/images/M/MV5BNTM4YThiMzktMDRlNi00NzAyLWI1YmQtNTdkMTNiN2Q0NzU1XkEyXkFqcGdeQXVyMTkxNjUyNQ@@._V1_SX300.jpg"},
  {id:2, Title:"Batman Begins", Year: "2005", imdbID: "tt0372784", Type:"movie", Poster:"https://m.media-amazon.com/images/M/MV5BZmUwNGU2ZmItMmRiNC00MjhlLTg5YWUtODMyNzkxODYzMmZlXkEyXkFqcGdeQXVyNTIzOTk5ODM@._V1_SX300.jpg"},
  {id:3, Title:"Ip Man", Year: "2008", imdbID: "tt1220719", Type:"movie", Poster:"https://m.media-amazon.com/images/M/MV5BNTFmMjM3M2UtOTIyZC00Zjk3LTkzODUtYTdhNGRmNzFhYzcyXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg"},
]

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();

  titles: Array<MediaTitle> = [];
  // titles: Array<MediaTitle> = DATA;

  constructor(private titleService: TitleService) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.titleService.getRequestedTitles().subscribe((titles: Array<MediaTitle>) => {
        this.titles = titles;
      })
    );
  }

  addNewTitle(title: MediaTitle) {
    this.subscriptions.add(
      this.titleService.addRequestedTitle(title).subscribe((title: MediaTitle) => {
        this.titles.push(title);
      })
    );

  }

}
