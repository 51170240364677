import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { MediaTitle } from 'src/app/shared/models/MediaTitle';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor(private apiService: ApiService) { }

  searchForTitle(title: string): Observable<Array<MediaTitle>> {
    return this.apiService.get('/api/v1/titles/search', new HttpParams().set('title', title));
  }

  getRequestedTitles(): Observable<Array<MediaTitle>> {
    return this.apiService.get('/api/v1/titles');
  }

  addRequestedTitle(title: MediaTitle): Observable<MediaTitle> {
      return this.apiService.post('/api/v1/titles', title);
  }

  deleteTitle(titleId: number) {
      this.apiService.delete('/api/v1/titles/', titleId);
  }

}
