import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { SearchComponent } from './modules/titles/pages/search/search.component';


const routes: Routes = [
  {
    path: 'search',
    component: SearchComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
