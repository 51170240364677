import { Component, OnInit, Input } from '@angular/core';
import { MediaTitle } from 'src/app/shared/models/MediaTitle';
import { TitleService } from 'src/app/core/services/title/title.service';

@Component({
  selector: 'app-title-display',
  templateUrl: './title-display.component.html',
  styleUrls: ['./title-display.component.scss']
})
export class TitleDisplayComponent {

  @Input()
  titles: Array<MediaTitle> = [];

  constructor(private titleService: TitleService) { }

  deleteTitle(title: MediaTitle) {
    this.titleService.deleteTitle(title.id);
    const index = this.titles.indexOf(title, 0);
    if (index > -1) {
      this.titles.splice(index, 1);
    }
  }

}
