<form>
  <div class="search-bar">
    <mat-grid-list cols="2">
        <mat-form-field>
            <mat-label>Title</mat-label>
            <input type="text"
                matInput
                [matAutocomplete]="auto"
                (keyup)="search($event)">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option class="option" *ngFor="let title of titles" [value]="title.Title" (onSelectionChange)="itemSelected(title)">
                        <app-search-result [title]="title"></app-search-result>
                    </mat-option>
                </mat-autocomplete>
        </mat-form-field>
        <button type='button' mat-raised-button (click)="addTitle()">Add</button>
    </mat-grid-list>
  </div>
</form>
