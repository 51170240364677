import { Component, OnInit, Input } from '@angular/core';
import { MediaTitle } from 'src/app/shared/models/MediaTitle';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {

  @Input()
  title: MediaTitle;

  constructor() { }

  ngOnInit(): void {
  }

}
