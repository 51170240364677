import { Component, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title/title.service';
import { MediaTitle } from 'src/app/shared/models/MediaTitle';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnDestroy {

  private subscription: Subscription = new Subscription();

  titles: Array<MediaTitle> = [];
  private selectedTitle: MediaTitle;

  @Output()
  titleToAdd: EventEmitter<MediaTitle> = new EventEmitter();

  constructor(private titleService: TitleService) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  search(event) {

    let title: string = event.target.value;
    if (title.length > 0) {

      this.subscription.add(
        this.titleService.searchForTitle(title).subscribe(titles => {

            if (titles.length > 0) {
              this.titles = titles;
            }

      }));

    } else {

      this.titles = [];

    }

  }

  addTitle() {
    this.titleToAdd.emit(this.selectedTitle);
  }

  itemSelected(item: MediaTitle) {
    this.selectedTitle = item;
  }

}
