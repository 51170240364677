<div value="title">
  <mat-card class="title-card">
    <div class="card-header">
      <div class="title">
        <mat-card-title>{{ title.Title }}</mat-card-title>
        <mat-card-subtitle>{{ title.Year }}</mat-card-subtitle>
      </div>
      <div class="delete-button">
        <button mat-icon-button color="warn" (click)="deleteTitle()">
          <mat-icon>delete_forever</mat-icon>
        </button>
      </div>
    </div>
    <img mat-card-image src="{{ title.Poster }}" alt="Poster">
  </mat-card>
</div>
